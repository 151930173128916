<template>
  <package-form
    :flight_campaign="flight_campaign"
    :partner_id="partner_id"
    :styles="styles"
    :packages="packages"
    :color="color"
    :products="products"
    :design="design"
    :handle_submit="handle_submit"
    :product_combine="product_combine"
    :node="props.node"
  />
</template>
<script setup>
import { computed, defineProps, nextTick, watch } from 'vue'
import { stores } from 'src/stores'
import keyBy from 'lodash.keyby'
import { NODE_BASE_PROPS } from 'src/constants'
import PackageForm from 'src/components/common/package-form'
import { DEFAULT_PACKAGE_FORM_DESIGN, ON_SUBMIT_EVENT } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()
const auth_store = stores.use_auth()
const packages = keyBy(
  context_store.packages.map((x) => ({
    ...x,
    product_codes: x?.product_codes?.join('--')
  })),
  'product_codes'
)

const products = keyBy(context_store.products, 'code')

const product_combine = computed(() => {
  const node = props.node
  if (!node) return ''
  const product_codes = node.meta.product_codes.filter((pc) => pc !== null)
  product_codes.sort()
  return product_codes.join('--')
})
const color = computed(() => {
  const meta = props.node.meta
  let result = '#1976d2'

  if (meta.color) result = meta.color

  return result
})

const styles = computed(() => {
  const styles = generate_common_node_styles(props.node)
  return { ...styles, '--q-primary': color.value }
})

const design = computed(() => {
  const meta = props.node.meta
  return meta?.design || DEFAULT_PACKAGE_FORM_DESIGN
})

const flight_campaign = computed(() => route.query?.flight_campaign || '')

const partner_id = computed(
  () => route.query?.partner_id || route.query?.sourceId || ''
)

const handle_submit = (data) => {
  if (!data) return

  // add access_token to data
  if (auth_store.user.access_token) {
    data.access_token = auth_store.user.access_token
  }

  // check for custom event before handling logic
  const custom_events = props.node.custom_events || {}
  const custom_onsubmit_handler = custom_events[ON_SUBMIT_EVENT]
  if (
    custom_onsubmit_handler &&
    typeof custom_onsubmit_handler === 'function'
  ) {
    // create event
    const $event = new CustomEvent(ON_SUBMIT_EVENT, {
      detail: data
    })
    // trigger callback
    custom_onsubmit_handler($event)
  }
  // default logic
  else {
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    if (data.process === 'hotel' && data.place_type === 'hotel') {
      location.href = `https://${booking_app_base_domain}/shopping/products/hotel/${data.place_id}?${qs}&office_domain=${location.hostname}`
      return
    }
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}&office_domain=${location.hostname}`
  }
}

watch(
  () => product_combine.value,
  async () => {
    await nextTick()
  }
)
</script>
